<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-08 10:27:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-13 15:03:23
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Consume/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="consume-report-container">
    <GoBack @goBack="goBack" title1="首页" title2="" title3="操作日志" />
    <div class="search-box">
      <a-range-picker
        class="search-item"
        format="YYYY-MM-DD"
        style="width: 240px"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select
        v-model="searchquery.accountUserId"
        :showSearch="false"
        :dataList="adminUserList"
        label="账户"
        class="search-item"
        @change="handleSearch"
      />
      <m-select
        v-model="searchquery.operateType"
        :showSearch="false"
        :dataList="typeList"
        label="操作类型"
        class="search-item"
        @change="handleSearch"
      />
      <a-input style="width: 240px" v-model="searchquery.operateContent" placeholder="请输入搜索内容" @change="handleChangeOperateContent"></a-input>
    </div>
    <div class="table-box">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <!-- 操作时间 -->
          <el-table-column prop="createTime" label="操作时间" min-width="140">
          </el-table-column>
          <!-- 所属账户 -->
          <el-table-column prop="accountUsername" label="所属账户" min-width="140">
          </el-table-column>
          <!--操作人 -->
          <el-table-column prop="username" label="操作人" min-width="140">
          </el-table-column>
          <!--操作类型 -->
          <el-table-column prop="operateType" label="操作类型" min-width="140">
            <template slot-scope="{ row }">
              {{ getOperateName(row.operateType) }}
            </template>
          </el-table-column>
          <!--操作对象 -->
          <el-table-column prop="operateObject" label="操作对象" min-width="140">
          </el-table-column>
          <!--动作 -->
          <el-table-column prop="operateAction" label="动作" min-width="140">
            <template slot-scope="{ row }">
              {{ getActionName(row.operateAction) }}
            </template>
          </el-table-column>
          <!--动作 -->
          <el-table-column prop="operateAction" label="动作" min-width="140">
            <template slot-scope="{ row }">
              {{ getActionName(row.operateAction) }}
            </template>
          </el-table-column>
          <!--操作内容 -->
          <el-table-column prop="operateContent" label="操作内容" min-width="240">
            <template slot-scope="{ row }">
              <div v-for="(item, index) in getOperateContentList(row.operateContent)" :key="index">
                <span style="font-weight: 600; margin-right: 8px">{{ index + 1 }}.</span>
                {{ item }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
import mixGetList from '@/mixins/getListForManage'
import { logPage } from '@/apiForManage/dashboard'
import { numFormat } from '@/utils/dealNumber'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import GoBack from '@/components/common/GoBack'
import { typeList, actionList } from './typeList'
export default {
  name: 'ConsumeReport',
  components: { GoBack },
  mixins: [mixDate, mixGetList, mixRateAndMoneyMethod],
  data () {
    return {
      typeList,
      searchquery: {
        endDate: '',
        startDate: '',
        accountUserId: undefined,
        operateType: undefined,
        page: 1,
        limit: 10
      },
      isLoading: false,
      tableData: [],
      total: 0,
      actionList,
      timer: null
    }
  },
  created () {
    this.initPageDate()
    this.getAdminUserList()
    this.getDataList()
  },
  methods: {
    getOperateContentList (content = '') {
      const list = content.split(',').filter((item) => item)
      return list
    },
    handleChangeOperateContent (e) {
      if (this.timer) {
        return
      }
      this.timer = setTimeout(() => {
        this.timer = null
        this.searchquery.page = 1
        this.getDataList()
      }, 1000)
    },
    getActionName (type) {
      return this.actionList.find((item) => item.id === type)?.name || '--'
    },
    getOperateName (type) {
      return this.typeList.find((item) => item.id === type)?.name || '--'
    },
    goBack () {
      this.$router.push('/dashboard')
    },
    numFormat,
    initPageDate () {
      // 初始化时间
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.searchquery.endDate = endDate
      this.searchquery.startDate = startDate
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        endDate: dateString[1],
        startDate: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      try {
        const { data = {} } = await logPage(this.searchquery)
        const { items = [], total = 0 } = data
        this.tableData = items
        this.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.consume-report-container {
  margin: calc(@mediumMargin + 62px) @mediumMargin @mediumMargin @mediumMargin;
  .search-box{
    display: flex;
    .search-item {
      box-sizing: border-box;
      padding-bottom: @compMargin;
      width: 240px;
      display: inline-block;
      margin-right: @compMargin;
    }
  }
  .table-box{
    margin-top: @smallMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
</style>
