/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-09 16:42:32
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-11 14:57:02
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Consume/dimesion.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const typeList = [
    {
      name: '账户',
      id: 0
    },
    {
      name: '供应商',
      id: 1
    },
    {
      name: '广告位',
      id: 2
    },
    {
      name: '广告源',
      id: 3
    },
    {
      name: '广告平台',
      id: 4
    },
    {
      name: '配置详情',
      id: 5
    },
    {
      name: '利润编辑',
      id: 6
    },
    {
      name: '频次控制',
      id: 7
    },
    {
      name: 'QPS',
      id: 8
    },
    {
      name: '账户管理',
      id: 9
    },
    {
      name: '角色权限',
      id: 10
    },
    {
      name: '屏蔽管理',
      id: 11
    }
]

export const actionList = [
  {
    name: '登录',
    id: 0
  },
  {
    name: '退出',
    id: 1
  },
  {
    name: '新增',
    id: 2
  },
  {
    name: '修改',
    id: 3
  },
  {
    name: '删除',
    id: 4
  }
]
